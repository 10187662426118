import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, MenuItem, Grid, Input } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";
import { Countries } from "../../../utils/Lookup";

import axios from "axios";
import Swal from "sweetalert2";

export default function CreateProductForm({ open, handleClose, fetchData }) {
  const { token } = useAuth();
  const identity = GetTokenIdentity(token);

  const [newData, setNewData] = useState({});

  const [errors, setErrors] = useState({
    product_name: false,
    product_price: false,
    cash_drawer_price: false,
    receipt_printer_price: false,
    popcorn_contract_3m_price: false,
    popcorn_contract_6m_price: false,
    popcorn_contract_12m_price: false,
    rocket_membership_6m_price: false,
    rocket_membership_12m_price: false,
    implementation_online_price: false,
    implementation_onsite_price: false,
    admin_fee_price: false,
    country_id: false,
    order_no: false,
  });
  const [helperText, setHelperText] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const setError = async (field, message) => {
    await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
  };

  const validate = async (field, value) => {
    if (field === "product_name" && value === "") {
      await setError("product_name", "Name is required.");
    } else if (field === "product_price" && value === "") {
      await setError("product_price", "Hardware Price is required.");
    } else if (field === "cash_drawer_price" && value === "") {
      await setError("cash_drawer_price", "Cash Drawer Price is required.");
    } else if (field === "receipt_printer_price" && value === "") {
      await setError("receipt_printer_price", "Receipt Printer Price is required.");
    } else if (field === "popcorn_contract_3m_price" && value === "") {
      await setError("popcorn_contract_3m_price", "Popcorn Cashier 3 Months Price is required.");
    } else if (field === "popcorn_contract_6m_price" && value === "") {
      await setError("popcorn_contract_6m_price", "Popcorn Cashier 6 Months Price is required.");
    } else if (field === "popcorn_contract_12m_price" && value === "") {
      await setError("popcorn_contract_12m_price", "Popcorn Cashier 12 Months Price is required.");
    } else if (field === "rocket_membership_6m_price" && value === "") {
      await setError("rocket_membership_6m_price", "Rocket Membership 6 Months Price is required.");
    } else if (field === "rocket_membership_12m_price" && value === "") {
      await setError("rocket_membership_12m_price", "Rocket Membership 12 Months Price is required.");
    } else if (field === "implementation_online_price" && value === "") {
      await setError("implementation_online_price", "Implementation Online Price is required.");
    } else if (field === "implementation_onsite_price" && value === "") {
      await setError("implementation_onsite_price", "Implementation Onsite Price is required.");
    } else if (field === "admin_fee_price" && value === "") {
      await setError("admin_fee_price", "Admin Fee Price is required.");
    } else if (field === "country_id" && value === "") {
      await setError("country_id", "Manage By is required.");
    } else if (field === "order_no" && value === "") {
      await setError("order_no", "Order No is required.");
    } else {
      await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
      await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
    }
  };

  useEffect(() => {
    if (open) {
      setNewData({
        product_name: "",
        product_price: "",
        product_image: "",
        cash_drawer_price: "",
        cash_drawer_image: "",
        receipt_printer_price: "",
        receipt_printer_image: "",
        popcorn_contract_3m_price: "",
        popcorn_contract_6m_price: "",
        popcorn_contract_12m_price: "",
        rocket_membership_6m_price: "",
        rocket_membership_12m_price: "",
        implementation_online_price: "",
        implementation_onsite_price: "",
        admin_fee_price: "",
        product_description: "",
        cash_drawer_description: "",
        receipt_printer_description: "",
        country_id: identity.user.country_id,
        order_no: 0,
      });
      setErrors({
        product_name: false,
        product_price: false,
        cash_drawer_price: false,
        receipt_printer_price: false,
        popcorn_contract_3m_price: false,
        popcorn_contract_6m_price: false,
        popcorn_contract_12m_price: false,
        rocket_membership_6m_price: false,
        rocket_membership_12m_price: false,
        implementation_online_price: false,
        implementation_onsite_price: false,
        admin_fee_price: false,
        country_id: false,
        order_no: false,
      });
      setHelperText({});
      setErrorMessage("");
    }
  }, [open, identity.user.country_id]);

  const handleCreateProduct = async () => {
    let isValid = true;

    if (newData["product_name"] === "") isValid = false;
    if (newData["product_price"] === "") isValid = false;
    if (newData["cash_drawer_price"] === "") isValid = false;
    if (newData["receipt_printer_price"] === "") isValid = false;
    if (newData["popcorn_contract_3m_price"] === "") isValid = false;
    if (newData["popcorn_contract_6m_price"] === "") isValid = false;
    if (newData["popcorn_contract_12m_price"] === "") isValid = false;
    if (newData["rocket_membership_6m_price"] === "") isValid = false;
    if (newData["rocket_membership_12m_price"] === "") isValid = false;
    if (newData["implementation_online_price"] === "") isValid = false;
    if (newData["implementation_onsite_price"] === "") isValid = false;
    if (newData["admin_fee_price"] === "") isValid = false;
    if (newData["country_id"] === "") isValid = false;
    if (newData["order_no"] === "") isValid = false;

    Object.keys(newData).forEach(async (field) => {
      await validate(field, newData[field]);
    });

    if (isValid) {
      await setErrorMessage("");
      await createProduct();
    } else {
      await setErrorMessage(`Please fill all the required fields.`);
    }
  };

  const createProduct = async () => {
    try {
      const {
        product_name,
        product_price,
        product_image,
        cash_drawer_price,
        cash_drawer_image,
        receipt_printer_price,
        receipt_printer_image,
        popcorn_contract_3m_price,
        popcorn_contract_6m_price,
        popcorn_contract_12m_price,
        rocket_membership_6m_price,
        rocket_membership_12m_price,
        implementation_online_price,
        implementation_onsite_price,
        admin_fee_price,
        product_description,
        cash_drawer_description,
        receipt_printer_description,
        country_id,
        order_no,
      } = newData;

      const formData = new FormData();
      formData.append("product_name", product_name);
      formData.append("product_price", product_price);
      formData.append("cash_drawer_price", cash_drawer_price);
      formData.append("receipt_printer_price", receipt_printer_price);
      formData.append("popcorn_contract_3m_price", popcorn_contract_3m_price);
      formData.append("popcorn_contract_6m_price", popcorn_contract_6m_price);
      formData.append("popcorn_contract_12m_price", popcorn_contract_12m_price);
      formData.append("rocket_membership_6m_price", rocket_membership_6m_price);
      formData.append("rocket_membership_12m_price", rocket_membership_12m_price);
      formData.append("implementation_online_price", implementation_online_price);
      formData.append("implementation_onsite_price", implementation_onsite_price);
      formData.append("admin_fee_price", admin_fee_price);
      formData.append("product_description", product_description);
      formData.append("cash_drawer_description", cash_drawer_description);
      formData.append("receipt_printer_description", receipt_printer_description);
      formData.append("country_id", country_id);
      formData.append("product_image", product_image);
      formData.append("cash_drawer_image", cash_drawer_image);
      formData.append("receipt_printer_image", receipt_printer_image);
      formData.append("order_no", order_no);

      const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        handleClose();
        Swal.fire({
          title: "Success",
          text: "Product created successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while creating the product. Please try again later.");
      }
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Product</DialogTitle>
      {errorMessage && (
        <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent style={{ paddingBottom: "0px" }}>
        <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
          <Chip label="Product Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="product_name"
              label="Name"
              type="text"
              fullWidth
              required
              error={errors.product_name}
              helperText={helperText.product_name}
              onChange={(e) => {
                setNewData({ ...newData, product_name: e.target.value });
                validate("product_name", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ paddingTop: "8px", paddingBottom: "4px" }}>
          <Chip label="Price Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="product_price"
              label="Hardware"
              type="number"
              fullWidth
              required
              error={errors.product_price}
              helperText={helperText.product_price}
              onChange={(e) => {
                setNewData({ ...newData, product_price: e.target.value });
                validate("product_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="cash_drawer_price"
              label="Cash Drawer"
              type="number"
              fullWidth
              required
              error={errors.cash_drawer_price}
              helperText={helperText.cash_drawer_price}
              onChange={(e) => {
                setNewData({ ...newData, cash_drawer_price: e.target.value });
                validate("cash_drawer_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="receipt_printer_price"
              label="Receipt Printer"
              type="number"
              fullWidth
              required
              error={errors.receipt_printer_price}
              helperText={helperText.receipt_printer_price}
              onChange={(e) => {
                setNewData({ ...newData, receipt_printer_price: e.target.value });
                validate("receipt_printer_price", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="popcorn_contract_3m_price"
              label="Popcorn Cashier 3 Months"
              type="number"
              fullWidth
              required
              error={errors.popcorn_contract_3m_price}
              helperText={helperText.popcorn_contract_3m_price}
              onChange={(e) => {
                setNewData({ ...newData, popcorn_contract_3m_price: e.target.value });
                validate("popcorn_contract_3m_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="popcorn_contract_6m_price"
              label="Popcorn Cashier 6 Months"
              type="number"
              fullWidth
              required
              error={errors.popcorn_contract_6m_price}
              helperText={helperText.popcorn_contract_6m_price}
              onChange={(e) => {
                setNewData({ ...newData, popcorn_contract_6m_price: e.target.value });
                validate("popcorn_contract_6m_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="popcorn_contract_12m_price"
              label="Popcorn Cashier 12 Months"
              type="number"
              fullWidth
              required
              error={errors.popcorn_contract_12m_price}
              helperText={helperText.popcorn_contract_12m_price}
              onChange={(e) => {
                setNewData({ ...newData, popcorn_contract_12m_price: e.target.value });
                validate("popcorn_contract_12m_price", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="rocket_membership_6m_price"
              label="Rocket Membership 6 Months"
              type="number"
              fullWidth
              required
              error={errors.rocket_membership_6m_price}
              helperText={helperText.rocket_membership_6m_price}
              onChange={(e) => {
                setNewData({ ...newData, rocket_membership_6m_price: e.target.value });
                validate("rocket_membership_6m_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="rocket_membership_12m_price"
              label="Rocket Membership 12 Months"
              type="number"
              fullWidth
              required
              error={errors.rocket_membership_12m_price}
              helperText={helperText.rocket_membership_12m_price}
              onChange={(e) => {
                setNewData({ ...newData, rocket_membership_12m_price: e.target.value });
                validate("rocket_membership_12m_price", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="implementation_online_price"
              label="Implementation Online"
              type="number"
              fullWidth
              required
              error={errors.implementation_online_price}
              helperText={helperText.implementation_online_price}
              onChange={(e) => {
                setNewData({ ...newData, implementation_online_price: e.target.value });
                validate("implementation_online_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="implementation_onsite_price"
              label="Implementation Onsite"
              type="number"
              fullWidth
              required
              error={errors.implementation_onsite_price}
              helperText={helperText.implementation_onsite_price}
              onChange={(e) => {
                setNewData({ ...newData, implementation_onsite_price: e.target.value });
                validate("implementation_onsite_price", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="admin_fee_price"
              label="Admin Fee (%)"
              type="number"
              fullWidth
              required
              error={errors.admin_fee_price}
              helperText={helperText.admin_fee_price}
              onChange={(e) => {
                setNewData({ ...newData, admin_fee_price: e.target.value });
                validate("admin_fee_price", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ paddingTop: "8px", paddingBottom: "4px" }}>
          <Chip label="Image Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="product_image"
              label="Hardware"
              type="file"
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  product_image: event.target.files && event.target.files[0] ? event.target.files[0] : "",
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="cash_drawer_image"
              label="Cash Drawer"
              type="file"
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  cash_drawer_image: event.target.files && event.target.files[0] ? event.target.files[0] : "",
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="receipt_printer_image"
              label="Receipt Printer"
              type="file"
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              onChange={(event) =>
                setNewData({
                  ...newData,
                  receipt_printer_image: event.target.files && event.target.files[0] ? event.target.files[0] : "",
                })
              }
            />
          </Grid>
        </Grid>
        <Divider style={{ paddingTop: "8px", paddingBottom: "4px" }}>
          <Chip label="Description Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="product_description"
              label="Hardware"
              fullWidth
              multiline
              rows={6}
              error={errors.product_description}
              helperText={helperText.product_description}
              onChange={(e) => {
                setNewData({ ...newData, product_description: e.target.value });
                validate("product_description", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="cash_drawer_description"
              label="Cash Drawer"
              fullWidth
              multiline
              rows={6}
              error={errors.cash_drawer_description}
              helperText={helperText.cash_drawer_description}
              onChange={(e) => {
                setNewData({ ...newData, cash_drawer_description: e.target.value });
                validate("cash_drawer_description", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="receipt_printer_description"
              label="Receipt Printer"
              fullWidth
              multiline
              rows={6}
              error={errors.receipt_printer_description}
              helperText={helperText.receipt_printer_description}
              onChange={(e) => {
                setNewData({ ...newData, receipt_printer_description: e.target.value });
                validate("receipt_printer_description", e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ paddingTop: "8px", paddingBottom: "4px" }}>
          <Chip label="Additional Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="country_id"
              select
              label="Manage By"
              type="number"
              defaultValue={identity.user.country_id}
              fullWidth
              required
              error={errors.country_id}
              helperText={helperText.country_id}
              onChange={(e) => {
                setNewData({ ...newData, country_id: e.target.value });
                validate("country_id", e.target.value);
              }}
            >
              {Countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="order_no"
              label="Order No"
              type="number"
              fullWidth
              required
              defaultValue={0}
              error={errors.order_no}
              helperText={helperText.order_no}
              onChange={(e) => {
                setNewData({ ...newData, order_no: e.target.value });
                validate("order_no", e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleCreateProduct} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
          Create
        </Button>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
