import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Alert, Stack, Button, TextField, Box, Typography, Container, Backdrop, Link, Grid } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { Copyright } from "../layouts/Copyright";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import HashLoader from "react-spinners/HashLoader";
import Swal from 'sweetalert2';

export const LoginPage = () => {
    const classes = CustomStyles;
    
    const location = useLocation();
    const message = location.state !== null && location.state.message !== null ? location.state.message : null;

    const { login, setToken } = useAuth();

    const [backdrop, setBackdrop] = useState(false);

    const showBackdrop = (visible) => {
        setBackdrop(visible);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const loginPayload = {
            email: data.get("email"),
            password: data.get("password")
        };

        if (loginPayload.email === "" || loginPayload.password === "") {
            return Swal.fire({
                icon: 'error',
                title: 'Invalid login credentials',
                text: 'Please enter your email and password.',
                confirmButtonColor: '#fe7320'
            });
        }

        loginPayload.email = loginPayload.email.trim();

        setToken(null); // Handle Multiple Tabs Login
        showBackdrop(true);
        
        setTimeout(() => {
            axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/login", loginPayload)
            .then(response => {
                if (response.data === undefined || response.data.data === undefined || response.data.data.token === undefined) alert(response.data.message);
                login(response.data.data);
                showBackdrop(false);
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid login credentials',
                    text: 'Please check your email and password and try again.',
                    confirmButtonColor: '#fe7320'
                });
                console.log(err);
                showBackdrop(false);
            });
        }, 1000); // Delay for Handle Local Storage Change
    };

    return (
        <div className={classes.parent}>
            <div className={classes.background} />
            <Container component="main" maxWidth="xs" sx={{ position: 'relative', top: 120, border: '1px solid #dfdfdf', borderRadius: '8px', bgcolor: '#ffffffba' }}>
                <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {
                        (message !== null) &&
                        <Stack sx={{ marginBottom: 2, width: '100%' }} spacing={2}>
                            <Alert style={{ whiteSpace: 'pre-wrap' }} severity="error">{message}</Alert>
                        </Stack>         
                    }
                    <img src={"rockbell.png"} alt="icon" style={{ marginTop: 26, marginBottom: 28, width: '80%' }} />
                    <Typography sx={{ marginTop: 1, marginBottom: 2, fontSize: 13, textAlign: "center" }}>
                        Welcome to the Rockbell Sales Promotion System.<br/>
                        Please log in to access your account.
                    </Typography> 
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField autoFocus margin="normal" fullWidth id="email" label="Email" name="email" InputLabelProps={{ shrink: true }}
                            autoComplete="email" />
                        <TextField margin="normal" fullWidth name="password" label="Password" type="password" id="password" InputLabelProps={{ shrink: true }}
                            autoComplete="current-password" />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, fontWeight: 'bold', color: 'white' }}>
                            Login
                        </Button>
                        <Grid container sx={{ mt: 1.5, alignItems: 'center', justifyContent: 'center'}}>
                            <Grid item>
                                <Link href="/forgot-password" variant="body2" sx={{ textDecoration: 'none', fontWeight: 'bold' }}>
                                    Forgot Password ?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
                    <HashLoader color="#ffffff" loading={true} size={150} />
                </Backdrop>
                <Copyright sx={{ mt: 4, mb: 4 }} />
            </Container>
        </div>
    );
};