import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Stack, Button, TextField, Box, Typography, Container, Backdrop, Link, Grid } from "@mui/material";
import { Copyright } from "../layouts/Copyright";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import HashLoader from "react-spinners/HashLoader";
import Swal from "sweetalert2";

export const ForgotPasswordPage = () => {
  const classes = CustomStyles;

  const location = useLocation();
  const message = location.state !== null && location.state.message !== null ? location.state.message : null;

  const [backdrop, setBackdrop] = useState(false);

  const showBackdrop = (visible) => {
    setBackdrop(visible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const forgetPasswordPayload = {
      email: data.get("email"),
    };

    if (forgetPasswordPayload.email === "") {
      return Swal.fire({
        icon: "error",
        title: "Invalid email",
        text: "Please enter your email.",
        confirmButtonColor: "#fe7320",
      });
    }

    showBackdrop(true);

    setTimeout(() => {
      axios
        .post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/forgot-password", forgetPasswordPayload)
        .then((response) => {
          if (response.data === undefined || response.data.data === undefined) alert(response.data.message);
          showBackdrop(false);
          Swal.fire({
            title: "Success",
            text: "A password reset link has been sent to your email. Please follow the instructions in the email to reset your password.",
            icon: "success",
            confirmButtonColor: "#fe7320",
          });
        })
        .catch((err) => {
          // alert("Oops, something went wrong. please try again later.");
          Swal.fire({
            icon: "error",
            title: "Invalid email",
            text: "Oops, something went wrong. please try again later.",
          });
          console.log(err);
          showBackdrop(false);
        });
    }, 1000); // Delay for Handle Local Storage Change
  };

  return (
    <div className={classes.parent}>
      <div className={classes.background} />
      <Container component="main" maxWidth="xs" sx={{ position: "relative", top: 120, border: "1px solid #dfdfdf", borderRadius: "8px", bgcolor: "#ffffffba" }}>
        <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {message !== null && (
            <Stack sx={{ marginBottom: 2, width: "100%" }} spacing={2}>
              <Alert style={{ whiteSpace: "pre-wrap" }} severity="error">
                {message}
              </Alert>
            </Stack>
          )}
          <Typography component="h1" variant="h5" fontWeight="bold" sx={{ marginTop: 1, marginBottom: 2, color: "#fe7320" }}>
            Reset Password
          </Typography>
          <Typography sx={{ marginTop: 1, marginBottom: 2, fontSize: 13, textAlign: "center" }}>Enter the email address associated with your account and we'll send you a link to reset your password.</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField autoFocus margin="normal" fullWidth id="email" label="Email" name="email" InputLabelProps={{ shrink: true }} autoComplete="email" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, fontWeight: "bold", color: "white" }}>
              Proceed
            </Button>
            <Typography sx={{ marginTop: 1.5, marginBottom: 2, fontSize: 13, textAlign: "center" }}>
              Password reset instructions will be emailed to you.
              <br />
              Please check your junk mail folder if you don't receive an email.
            </Typography>
            <Grid container sx={{ mt: 1.5, alignItems: "center", justifyContent: "center" }}>
              <Grid item>
                <Link href="/login" variant="body2" sx={{ textDecoration: "none", fontWeight: "bold" }}>
                  Return to Login Page ?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
          <HashLoader color="#ffffff" loading={true} size={150} />
        </Backdrop>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </div>
  );
};
