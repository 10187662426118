import { Routes, Route } from "react-router-dom";

import { ProtectedLayout } from "./components/layout/Protected";
import { PublicLayout } from "./components/layout/Public";

import { DashboardPage } from "./pages/Dashboard";
import { LoginPage } from "./pages/Login";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { ResetPasswordPage } from "./pages/ResetPassword";
import { ProductsPage } from "./pages/Products";
import { LogsPage } from "./pages/Logs";
import { UsersPage } from "./pages/Users";
import { GroupsPage } from "./pages/Groups";
import { NotFoundPage } from "./pages/NotFound";

import { PricePage } from "./pages/Price";

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<PublicLayout excludedPaths={["/", "/price"]} />}>
        <Route path="/" element={<PricePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/logs" element={<LogsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/groups" element={<GroupsPage />} />
      </Route>
    </Routes>
  );
}
