import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, CardHeader, Dialog } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import DateRangePicker from "../components/common/DateRangePicker";
import ViewLogForm from "../components/forms/logs/View";

export const LogsPage = () => {
  const { token, logout } = useAuth();
  const identity = GetTokenIdentity(token);

  const descriptionInputRef = useRef(null);

  const classes = CustomStyles;

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filter, setFilter] = useState({ start_date: null, end_date: null });
  const [sort, setSort] = useState({});

  const [open, setOpen] = useState({ view: false });
  const [selectedRow, setSelectedRow] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleOpen = (type, row) => {
    setOpen({ ...open, [type]: true });
    setSelectedRow(row);
  };

  const handleClose = (type) => {
    setOpen({ ...open, [type]: false });
    setSelectedRow(null);
  };

  const handleResetFilter = () => {
    setPage(0);
    setFilter({ start_date: null, end_date: null, status: "" });
    setStartDate(null);
    setEndDate(null);
  };

  const handleFilterChange = (event) => {
    setPage(0);
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const fetchData = useCallback(async () => {
    try {
      let customFilter = { ...filter };
      customFilter.start_date = moment(customFilter.start_date).format("YYYY-MM-DD");
      customFilter.end_date = moment(customFilter.end_date).format("YYYY-MM-DD");
      const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/logs`, {
        params: {
          page: page + 1,
          perPage,
          filter: JSON.stringify(customFilter),
          sort,
        },
      });
      setData(data.data.logs);
      setCount(data.data.total);
    } catch (error) {
      if (error.response.status === 401) {
        console.log("Unauthorized: ", error);
        logout("Your account has been logged out of a session.");
      } else {
        console.log(error);
      }
    }
  }, [page, perPage, filter, sort, logout]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, perPage, filter, sort]);

  useEffect(() => {
    setPage(0);
    setFilter((prevFilter) => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
  }, [startDate, endDate]);

  const columns = [
    { title: "Trx Date", field: "created_at", render: (rowData) => moment.utc(rowData.created_at).format("YYYY-MM-DD HH:mm:ss"), witdh: "50%" },
    { title: "Description", field: "full_description", cellStyle: { width: 1360, maxWidth: 1360 }, headerStyle: { width: 1360, maxWidth: 1360 } },
  ];

  return (
    <div>
      <div className={classes.background} />
      <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
        {identity.user.role_id === 1 ? (
          <MaterialTable
            title="Log List"
            icons={Icons}
            columns={columns}
            data={data}
            page={page}
            totalCount={count}
            style={{ padding: "24px" }}
            options={{
              pageSize: perPage,
              pageSizeOptions: [10, 20, 30, 50],
              search: false,
              filtering: false,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#f1f1f1",
                color: "#212122",
              },
              maxBodyHeight: "600px",
              paginationType: "stepped",
              padding: "dense",
              draggable: false,
            }}
            localization={{
              pagination: {
                labelRowsPerPage: "",
                labelDisplayedRows: "{from}-{to} of {count}",
                labelRowsSelect: "rows",
              },
              toolbar: {
                nRowsSelected: "{0} row(s) selected",
              },
              header: {
                actions: "Actions",
              },
              body: {
                emptyDataSourceMessage: "No data to display",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            actions={[
              {
                icon: () => <Icons.Preview />,
                tooltip: "View Log Information",
                onClick: (event, rowData) => handleOpen("view", rowData),
              },
            ]}
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
            components={{
              Toolbar: (props) => (
                <>
                  <div>
                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                      <Grid item xs={12} sm={6}>
                        <CardHeader
                          sx={{ p: 0 }}
                          title={
                            <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                              Log List
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <form>
                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Trx Date (Start From)"
                          fullWidth
                          type="text"
                          size="small"
                          value={startDate ? formatDate(startDate) : ""}
                          // onChange={(event) => setStartDate(event.target.value)}
                          onClick={() => setIsDialogOpen(true)}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                          <DateRangePicker onClose={handleDialogClose} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                        </Dialog>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Trx Date (End From)"
                          fullWidth
                          type="text"
                          size="small"
                          value={endDate ? formatDate(endDate) : ""}
                          // onChange={(event) => setEndDate(event.target.value)}
                          onClick={() => setIsDialogOpen(true)}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          autoComplete="off"
                          fullWidth
                          label="Description"
                          name="description"
                          variant="outlined"
                          autoFocus={descriptionInputRef.current === document.activeElement}
                          value={filter.description}
                          onChange={handleFilterChange}
                          inputRef={descriptionInputRef}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="error"
                          sx={{
                            color: "#ffffff",
                            marginTop: "2px",
                          }}
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </>
              ),
            }}
          />
        ) : (
          <Typography variant="subtitle2">You don't have permission to view logs.</Typography>
        )}
        <ViewLogForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
      </Container>
    </div>
  );
};
