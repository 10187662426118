import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import { DateRange } from "react-date-range";

const DateRangePicker = ({ onClose, startDate, setStartDate, endDate, setEndDate }) => {
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [rangeSelected, setRangeSelected] = useState(false);

  const handleSelect = (range) => {
    setRangeStartDate(range.selection.startDate);
    setRangeEndDate(range.selection.endDate);
    setRangeSelected(true);
  };

  const handleRangeClear = () => {
    setRangeSelected(false);
  };

  const handleReset = () => {
    setRangeStartDate(null);
    setRangeEndDate(null);
    handleRangeClear();
    setStartDate(null);
    setEndDate(null);
    onClose();
  };

  const handleOnClose = () => {
    setStartDate(rangeStartDate);
    setEndDate(rangeEndDate);
    onClose();
  };

  useEffect(() => {
    if (!rangeSelected && startDate !== null && endDate !== null) {
      setRangeStartDate(startDate);
      setRangeEndDate(endDate);
    }
  }, [rangeSelected, startDate, endDate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "16px" }}>
      <DateRange
        onChange={handleSelect}
        onRangeClear={handleRangeClear}
        startDate={rangeStartDate}
        endDate={rangeEndDate}
        ranges={[
          {
            startDate: rangeStartDate,
            endDate: rangeEndDate ? rangeEndDate : new Date(""),
            key: "selection",
          },
        ]}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleOnClose} variant="contained" color="primary" style={{ color: "white", marginRight: "12px" }}>
          Confirm
        </Button>
        <Button onClick={handleReset} variant="contained" color="error" style={{ color: "white" }}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default DateRangePicker;
