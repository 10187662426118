import React from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={3}></Grid>
          <Grid xs={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">The page you’re looking for doesn’t exist.</Typography>
            <Button variant="contained" sx={{ color: "#ffffff", margin: 3 }} onClick={handleButtonClick}>
              Back Home
            </Button>
          </Grid>
          <Grid xs={3}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};
