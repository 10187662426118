export const Countries = [
  { id: 158, name: "Malaysia" },
  { id: 198, name: "Singapore" },
  { id: 101, name: "Indonesia" },
];

export const ProductStatus = [
  { id: 0, name: "No" },
  { id: 1, name: "Yes" },
];

export const Roles = [
  { id: 1, name: "Aministrator" },
  { id: 2, name: "Manager" },
  { id: 3, name: "Support" },
];

export const UserStatus = [
  { id: 0, name: "Active" },
  { id: 1, name: "In-Active" },
];
