import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, Chip, Grid } from "@mui/material";

import moment from "moment";

export default function ViewLogForm({ open, handleClose, rowData }) {
  if (!rowData || Object.keys(rowData).length === 0) return null;

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">View Log Information</DialogTitle>
      <DialogContent style={{ paddingBottom: "0px" }}>
        <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
          <Chip label="Log Details" />
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField autoComplete="off" margin="dense" label="Trx Date" type="text" fullWidth defaultValue={moment.utc(rowData.created_at).format("YYYY-MM-DD HH:mm:ss")} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              margin="dense"
              label="Action Type"
              type="text"
              fullWidth
              defaultValue={rowData.action_type}
              inputProps={{
                style: { textTransform: "uppercase", readOnly: true },
              }}
              style={{ backgroundColor: "#ededed" }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField autoComplete="off" margin="dense" label="User Id" type="text" fullWidth defaultValue={rowData.user_id} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={3}>
            <TextField autoComplete="off" margin="dense" label="User Name" type="text" fullWidth defaultValue={rowData.user_name} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              margin="dense"
              label="Table Name"
              type="text"
              fullWidth
              defaultValue={rowData.table_name}
              inputProps={{
                style: { textTransform: "uppercase", readOnly: true },
              }}
              style={{ backgroundColor: "#ededed" }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField autoComplete="off" margin="dense" label="Related Id" type="text" fullWidth defaultValue={rowData.related_id} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={3}>
            <TextField autoComplete="off" margin="dense" label="Related Name" type="text" fullWidth defaultValue={rowData.related_name} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <TextField autoComplete="off" margin="dense" label="Description" type="text" fullWidth defaultValue={rowData.full_description} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
        <TextField
          autoComplete="off"
          margin="dense"
          label="Payload"
          type="text"
          multiline
          rows={3}
          fullWidth
          defaultValue={rowData.payload ? new TextDecoder().decode(new Uint8Array(JSON.parse(JSON.stringify(rowData.payload)).data)) : ""}
          style={{ backgroundColor: "#ededed" }}
          inputProps={{ readOnly: true }}
        />
        <TextField autoComplete="off" margin="dense" label="Change" type="text" multiline rows={4} fullWidth defaultValue={rowData.change} style={{ backgroundColor: "#ededed" }} inputProps={{ readOnly: true }} />
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
