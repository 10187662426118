import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar as MuiAppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";

import ChangePasswordForm from "../components/forms/users/ChangePassword";
import ProfileForm from "../components/forms/users/Profile";

export const AppBar = ({ pages }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState({
    profile: false,
    changePassword: false,
  });

  const handleOpen = (type, row) => {
    setOpen({ ...open, [type]: true });
  };

  const handleClose = (type) => {
    setOpen({ ...open, [type]: false });
  };

  const { token, logout } = useAuth();
  const identity = GetTokenIdentity(token);

  const handleLogout = (event) => {
    logout(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);

    if (path) {
      navigate(path);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <MuiAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            noWrap
            component="div"
            fontWeight="bold"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              color: "white",
            }}
          >
            Rockbell
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" sx={{ color: "white" }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages?.map((page) => {
                if (Array.isArray(page.role) ? page.role.includes(identity.user.role_id) : page.role === identity.user.role_id) {
                  return (
                    <MenuItem key={page.label} onClick={() => handleCloseNavMenu(page.path)}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  );
                }
                return null;
              })}
              {!!token && (
                <MenuItem key={"logout"} onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            {/*  */}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages?.map((page) => {
              if (Array.isArray(page.role) ? page.role.includes(identity.user.role_id) : page.role === identity.user.role_id) {
                return (
                  <Button
                    key={page.label}
                    onClick={() => handleCloseNavMenu(page.path)}
                    sx={{
                      my: 2,
                      mx: 0.8,
                      color: "white",
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    {page.label}
                  </Button>
                );
              }
              return null;
            })}
            {!!token && (
              <Button
                key={"logout3"}
                onClick={handleLogout}
                sx={{
                  my: 2,
                  mx: 0.8,
                  color: "white",
                  display: "block",
                  fontWeight: "600",
                }}
              >
                {"logout"}
              </Button>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!!token && (
              <Button
                key={"logout2"}
                sx={{
                  my: 2,
                  mx: 0.8,
                  color: "white",
                  display: "block",
                  fontWeight: "600",
                }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
              >
                @ {identity.user.user_name}
              </Button>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem
                                onClick={() => {
                                    handleCloseUserMenu();
                                    handleOpen("profile", {
                                        user_id: identity.user.user_id
                                    });
                                }}
                            >
                                Profile
                            </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  handleOpen("changePassword", {
                    user_id: identity.user.user_id,
                  });
                }}
              >
                Change Password
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <ProfileForm open={open.profile} handleClose={() => handleClose("profile")} rowData={{ user_id: identity.user.user_id }} />
        <ChangePasswordForm open={open.changePassword} handleClose={() => handleClose("changePassword")} rowData={{ user_id: identity.user.user_id }} />
      </Container>
    </MuiAppBar>
  );
};
