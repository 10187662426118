import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, ButtonGroup, CardHeader, MenuItem } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Countries, ProductStatus } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import MaterialTable from "material-table";

import CreateProductForm from "../components/forms/products/Create";
import EditProductForm from "../components/forms/products/Edit";
import DeleteProductForm from "../components/forms/products/Delete";

export const ProductsPage = () => {
  const { token, logout } = useAuth();
  const identity = GetTokenIdentity(token);

  const classes = CustomStyles;

  const nameInputRef = useRef(null);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filter, setFilter] = useState({ is_active: 1, country_id: identity.user.country_id });
  const [sort, setSort] = useState({});

  const [selectedRow, setSelectedRow] = useState({});

  const [open, setOpen] = useState({
    create: false,
    edit: false,
  });

  const handleOpen = (type, row) => {
    setOpen({ ...open, [type]: true });
    setSelectedRow(row);
  };

  const handleClose = (type) => {
    setOpen({ ...open, [type]: false });
    setSelectedRow(null);
  };

  const handleResetFilter = () => {
    setPage(0);
    setFilter({ is_active: 1, country_id: identity.user.country_id });
  };

  const handleFilterChange = (event) => {
    setPage(0);
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products`, {
        params: {
          page: page + 1,
          perPage,
          filter: JSON.stringify(filter),
          sort,
        },
      });
      setData(data.data.products);
      setCount(data.data.total);
    } catch (error) {
      if (error.response.status === 401) {
        console.log("Unauthorized: ", error);
        logout("Your account has been logged out of a session.");
      } else {
        console.log(error);
      }
    }
  }, [page, perPage, filter, sort, logout]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, perPage, filter, sort]);

  const columns = [
    {
      title: "Name",
      field: "product_name",
      cellStyle: { width: 230, maxWidth: 230 },
      headerStyle: { width: 230, maxWidth: 230 },
    },
    { title: "Hardware", field: "product_price", width: "80%" },
    { title: "Cash Drawer", field: "cash_drawer_price", width: "80%" },
    { title: "Receipt Printer", field: "receipt_printer_price", width: "80%" },
    { title: "Popcorn Cashier 3 Months", field: "popcorn_contract_3m_price", width: "80%" },
    { title: "Popcorn Cashier 6 Months", field: "popcorn_contract_6m_price", width: "80%" },
    { title: "Popcorn Cashier 12 Months", field: "popcorn_contract_12m_price", width: "80%" },
    { title: "Rocket Membership 6 Months", field: "rocket_membership_6m_price", width: "80%" },
    { title: "Rocket Membership 12 Months", field: "rocket_membership_12m_price", width: "80%" },
    { title: "Implementationt Online", field: "implementation_online_price", width: "80%" },
    { title: "Implementationt Onsite", field: "implementation_onsite_price", width: "80%" },
    { title: "Admin Fee", field: "admin_fee_price", width: "80%", render: (rowData) => `${rowData.admin_fee_price}%` },
    { title: "Order No", field: "order_no", width: "80%" },
  ];

  const actions = [];

  if (identity.user.role_id === 1 || identity.user.role_id === 2) {
    actions.push({
      icon: () => <Icons.Edit />,
      tooltip: "Edit Product Information",
      onClick: (event, rowData) => handleOpen("edit", rowData),
    });
    actions.push({
      icon: () => <Icons.Delete />,
      tooltip: "Delete Product",
      onClick: (event, rowData) => DeleteProductForm(rowData, fetchData),
    });
  } else {
    actions.push({
      icon: () => <Icons.Preview />,
      tooltip: "View Product Information",
      onClick: (event, rowData) => handleOpen("view", rowData),
    });
  }

  return (
    <div>
      <div className={classes.background} />
      <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
        <MaterialTable
          title="Product List"
          icons={Icons}
          columns={columns}
          data={data}
          page={page}
          totalCount={count}
          style={{ padding: "24px" }}
          options={{
            pageSize: perPage,
            pageSizeOptions: [10, 20, 30, 50],
            search: false,
            filtering: false,
            sorting: false,
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: "bold",
              backgroundColor: "#f1f1f1",
              color: "#212122",
            },
            maxBodyHeight: "600px",
            paginationType: "stepped",
            padding: "dense",
            draggable: false,
          }}
          localization={{
            pagination: {
              labelRowsPerPage: "",
              labelDisplayedRows: "{from}-{to} of {count}",
              labelRowsSelect: "rows",
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
            },
            header: {
              actions: "Actions",
            },
            body: {
              emptyDataSourceMessage: "No data to display",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          actions={actions}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
          components={{
            Toolbar: (props) => (
              <>
                <div>
                  <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                    <Grid item xs={12} sm={3}>
                      <CardHeader
                        sx={{ p: 0 }}
                        title={
                          <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                            Product List
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                      {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                        <ButtonGroup>
                          <Button
                            onClick={() => handleOpen("create", null)}
                            variant="outlined"
                            startIcon={<Icons.AddIcon fontWeight="bold" />}
                            color="primary"
                            sx={{
                              fontWeight: 800,
                              paddingTop: 0.5,
                              paddingBottom: 0.5,
                            }}
                          >
                            Create
                          </Button>
                        </ButtonGroup>
                      )}
                    </Grid>
                  </Grid>
                </div>

                <form>
                  <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                    <Grid item xs={12} sm={3} md={3}>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        label="Name"
                        name="product_name"
                        variant="outlined"
                        autoFocus={nameInputRef.current === document.activeElement}
                        value={filter.product_name}
                        onChange={handleFilterChange}
                        inputRef={nameInputRef}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                      <TextField
                        fullWidth
                        select
                        label="Is Active"
                        variant="outlined"
                        name="is_active"
                        value={filter.is_active}
                        onChange={handleFilterChange}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value={""}>All</MenuItem>
                        {ProductStatus.map((status) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                      <TextField
                        fullWidth
                        select
                        label="Manage By"
                        variant="outlined"
                        name="country_id"
                        value={filter.country_id}
                        onChange={handleFilterChange}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <MenuItem value={""}>All</MenuItem>
                        {Countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        sx={{
                          color: "#ffffff",
                          marginTop: "2px",
                        }}
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            ),
          }}
        />
        <CreateProductForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} />
        <EditProductForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
      </Container>
    </div>
  );
};
