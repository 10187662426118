import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, MenuItem, Grid, InputAdornment, IconButton, Alert } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Countries, Roles } from "../../../utils/Lookup";

import axios from "axios";
import Swal from "sweetalert2";

export default function CreateUserForm({ open, handleClose, fetchData }) {
  const [newData, setNewData] = useState({});

  const [errors, setErrors] = useState({ full_name: false, email: false, password_hash: false, country_id: false, role_id: false });
  const [showPassword, setShowPassword] = useState(false);
  const [helperText, setHelperText] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const setError = async (field, message) => {
    await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
  };

  const validate = async (field, value) => {
    if (field === "full_name" && value === "") {
      await setError("full_name", "Full Name is required.");
    } else if (field === "email" && value === "") {
      await setError("email", "Email is required.");
    } else if (field === "password_hash" && value.length < 6) {
      await setError("password_hash", "Password must be at least 6 characters.");
    } else if (field === "country_id" && value === "") {
      await setError("country_id", "Manage By is required.");
    } else if (field === "role_id" && value === "") {
      await setError("role_id", "Role is required.");
    } else if (field === "status" && value === "") {
      await setError("status", "Status is required.");
    } else {
      await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
      await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
    }
  };

  useEffect(() => {
    if (open) {
      setNewData({ full_name: "", email: "", password_hash: "", country_id: "", role_id: "" });
      setErrors({ full_name: false, email: false, password_hash: false, country_id: false, role_id: false });
      setShowPassword(false);
      setHelperText({});
      setErrorMessage("");
    }
  }, [open]);

  const handleCreateUser = async () => {
    let isValid = true;

    if (newData["full_name"] === "") isValid = false;
    if (newData["email"] === "") isValid = false;
    if (newData["password_hash"].length < 6) isValid = false;
    if (newData["country_id"] === "") isValid = false;
    if (newData["role_id"] === "") isValid = false;

    Object.keys(newData).forEach(async (field) => {
      await validate(field, newData[field]);
    });

    if (isValid) {
      await setErrorMessage("");
      await createUser();
    } else {
      await setErrorMessage(`Please fill all the required fields.`);
    }
  };

  const createUser = async () => {
    try {
      const { role_id, full_name, email, password_hash, country_id } = newData;
      const password = password_hash;
      const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/create", {
        role_id,
        full_name,
        email,
        password,
        country_id,
      });

      if (response.status === 201) {
        handleClose();
        Swal.fire({
          title: "Success",
          text: "User created successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error === "Email already exists") setErrorMessage("This email address is already in use. Please use a different one.");
        else setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while creating the user. Please try again later.");
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New User</DialogTitle>
      {errorMessage && (
        <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent style={{ paddingBottom: "0px" }}>
        <TextField
          autoFocus
          autoComplete="off"
          margin="dense"
          id="full_name"
          label="Full Name"
          type="text"
          fullWidth
          required
          error={errors.full_name}
          helperText={helperText.full_name}
          onChange={(e) => {
            setNewData({ ...newData, full_name: e.target.value });
            validate("full_name", e.target.value);
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          required
          error={errors.email}
          helperText={helperText.email}
          onChange={(e) => {
            setNewData({ ...newData, email: e.target.value });
            validate("email", e.target.value);
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="password_hash"
          label="Password (At least 6 characters)"
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          error={errors.password_hash}
          helperText={helperText.password_hash}
          onChange={(e) => {
            setNewData({ ...newData, password_hash: e.target.value });
            validate("password_hash", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              autoComplete="off"
              id="role_id"
              select
              label="Role"
              type="number"
              defaultValue=""
              fullWidth
              required
              error={errors.role_id}
              helperText={helperText.role_id}
              onChange={(e) => {
                setNewData({ ...newData, role_id: e.target.value });
                validate("role_id", e.target.value);
              }}
            >
              {Roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="country_id"
              select
              label="Manage By"
              type="number"
              defaultValue=""
              fullWidth
              required
              error={errors.country_id}
              helperText={helperText.country_id}
              onChange={(e) => {
                setNewData({ ...newData, country_id: e.target.value });
                validate("country_id", e.target.value);
              }}
            >
              {Countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleCreateUser} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
          Create
        </Button>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
