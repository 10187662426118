import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Alert } from "@mui/material";
import { Countries, Roles, UserStatus } from "../../../utils/Lookup";

import axios from "axios";
import Swal from "sweetalert2";

export default function ProfileForm({ open, handleClose, rowData }) {
  const [editData, setEditData] = useState({});

  const [errors, setErrors] = useState({ full_name: false, email: false, password_hash: false, country_id: false, role_id: false, status: false });
  const [helperText, setHelperText] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (rowData) {
      setEditData(rowData);
    }
    if (open) {
      setErrors({ full_name: false, email: false, password_hash: false, country_id: false, role_id: false, status: false });
      setHelperText({});
      setErrorMessage("");
    }
  }, [rowData, open]);

  if (!rowData) return null;

  const setError = async (field, message) => {
    await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
  };

  const validate = async (field, value) => {
    if (field === "full_name" && value === "") {
      await setError("full_name", "Full Name is required.");
    } else if (field === "email" && value === "") {
      await setError("email", "Email is required.");
    } else if (field === "password_hash" && value.length < 6) {
      await setError("password_hash", "Password must be at least 6 characters.");
    } else if (field === "country_id" && value === "") {
      await setError("country_id", "Manage By is required.");
    } else if (field === "role_id" && value === "") {
      await setError("role_id", "Role is required.");
    } else if (field === "status" && value === "") {
      await setError("status", "Status is required.");
    } else {
      await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
      await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
    }
  };

  const handleEditUser = async () => {
    const { role_id, full_name, email, country_id, status } = editData;
    try {
      const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/${rowData.user_id}`, {
        role_id,
        full_name,
        email,
        country_id,
        status,
      });

      if (response.status === 200) {
        handleClose();
        Swal.fire({
          title: "Success",
          text: "User information updated successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error === "Email already exists") setErrorMessage("This email address is already in use. Please use a different one.");
        else setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while updating the user information. Please try again later.");
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit User Information</DialogTitle>
      {errorMessage && (
        <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent style={{ paddingBottom: "0px" }}>
        <TextField
          autoComplete="off"
          margin="dense"
          id="full_name"
          label="Full Name"
          type="text"
          fullWidth
          required
          defaultValue={rowData.full_name}
          error={errors.full_name}
          helperText={helperText.full_name}
          onChange={(e) => {
            setEditData({ ...editData, full_name: e.target.value });
            validate("full_name", e.target.value);
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
          required
          defaultValue={rowData.email}
          error={errors.email}
          helperText={helperText.email}
          onChange={(e) => {
            setEditData({ ...editData, email: e.target.value });
            validate("email", e.target.value);
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              autoComplete="off"
              id="role_id"
              select
              label="Role"
              type="number"
              defaultValue={rowData.role_id}
              fullWidth
              required
              error={errors.role_id}
              helperText={helperText.role_id}
              onChange={(e) => {
                setEditData({ ...editData, role_id: e.target.value });
                validate("role_id", e.target.value);
              }}
            >
              {Roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="country_id"
              select
              label="Manage By"
              type="number"
              defaultValue={rowData.country_id}
              fullWidth
              required
              error={errors.country_id}
              helperText={helperText.country_id}
              onChange={(e) => {
                setEditData({ ...editData, country_id: e.target.value });
                validate("country_id", e.target.value);
              }}
            >
              {Countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <TextField
          margin="dense"
          autoComplete="off"
          id="status"
          select
          label="Status"
          type="number"
          defaultValue={rowData.status}
          fullWidth
          required
          error={errors.status}
          helperText={helperText.status}
          onChange={(e) => {
            setEditData({ ...editData, status: e.target.value });
            validate("status", e.target.value);
          }}
        >
          {UserStatus.map((status) => (
            <MenuItem key={status.id} value={status.id}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleEditUser} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
          Update
        </Button>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
