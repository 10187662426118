import { useEffect, useState, useCallback } from "react";
import { Typography, Container, Grid, Card, CardContent, ToggleButtonGroup, Box, CardHeader, Tooltip, Button, ButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import _ from "lodash";
import axios from "axios";

import PriceHardwareDetail from "./PriceHardwareDetail";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
  backgroundColor: "#f9fafb7a",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    borderColor: "#fe7320 !important",
  },
}));

export const PricePage = () => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [open, setOpen] = useState({
    hardware: false,
    cash_drawer: false,
    receipt_printer_price: false,
  });

  const handleOpen = (type, row) => {
    setOpen({ ...open, [type]: true });
  };

  const handleClose = (type) => {
    setOpen({ ...open, [type]: false });
  };

  const [quantity, setQuantity] = useState({
    cash_drawer: 1,
    receipt_printer: 1,
  });

  const addCashDrawerQuantity = () => {
    setQuantity({ ...quantity, cash_drawer: quantity.cash_drawer + 1 });
  };

  const reductCashDrawerQuantity = () => {
    setQuantity({ ...quantity, cash_drawer: quantity.cash_drawer - 1 < 1 ? 1 : quantity.cash_drawer - 1 });
  };

  const addReceiptPrinterQuantity = () => {
    setQuantity({ ...quantity, receipt_printer: quantity.receipt_printer + 1 });
  };

  const reductReceiptPrinterQuantity = () => {
    setQuantity({ ...quantity, receipt_printer: quantity.receipt_printer - 1 < 1 ? 1 : quantity.receipt_printer - 1 });
  };

  const [price, setPrice] = useState({
    hardware_price: 0,
    cash_drawer_price: 0,
    receipt_printer_price: 0,
    popcorn_contract_price: 0,
    rocket_contract_price: 0,
    implementation_price: 0,
    admin_fee_price: 0,
    total_price: 0,
    installment_price: 0,
  });

  const [visible, setVisible] = useState({
    cash_drawer: false,
    receipt_printer: false,
    popcorn_contract: false,
    rocket_contract: false,
    implementation: false,
    installment: false,
  });

  const [expanded, setExpanded] = useState({
    hardware: true,
    cash_drawer: true,
    receipt_printer: true,
    popcorn_contract: true,
    rocket_contract: true,
    implementation: true,
    installment: true,
  });

  const handleHardwareAccordionClick = () => {
    setExpanded({ ...expanded, hardware: !expanded.hardware });
  };

  const handleCashDrawerAccordionClick = () => {
    setExpanded({ ...expanded, cash_drawer: !expanded.cash_drawer });
  };

  const handleReceiptPrinterAccordionClick = () => {
    setExpanded({ ...expanded, receipt_printer: !expanded.receipt_printer });
  };

  const handlePopcornContractAccordionClick = () => {
    setExpanded({ ...expanded, popcorn_contract: !expanded.popcorn_contract });
  };

  const handleRocketContractAccordionClick = () => {
    setExpanded({ ...expanded, rocket_contract: !expanded.rocket_contract });
  };

  const handleImplementationAccordionClick = () => {
    setExpanded({ ...expanded, implementation: !expanded.implementation });
  };

  const handleInstallmentAccordionClick = () => {
    setExpanded({ ...expanded, installment: !expanded.installment });
  };

  const handleHardwareInformationOpen = () => {
    handleOpen("hardware");
  };

  const [hardwareSection, setHardwareSection] = useState(null);
  const [hardwareSectionContent, sethardwareSectionContent] = useState([]);
  const [cashDrawerSection, setCashDrawerSection] = useState(null);
  const [receiptPrinterSection, setReceiptPrinterSection] = useState(null);
  const [popcornCashierSection, setPopcornCashierSection] = useState(null);
  const [rocketMembershipSection, setRocketMembershipSection] = useState(null);
  const [implementationSection, setImplementationSection] = useState(null);
  const [installmentSection, setInstallmentSection] = useState(null);

  const hardwareSectionChange = (event, newHardwareSection) => {
    setHardwareSection(newHardwareSection);
    setVisible((prevVisible) => ({ ...prevVisible, cash_drawer: true }));
    setExpanded({ ...expanded, hardware: false });
  };

  const cashDrawerSectionChange = (event, newCashDrawerSection) => {
    setCashDrawerSection(newCashDrawerSection);
    setVisible({ ...visible, receipt_printer: true });
    setExpanded({ ...expanded, cash_drawer: false });
  };

  const receiptPrinterSectionChange = (event, newReceiptPrinterSection) => {
    setReceiptPrinterSection(newReceiptPrinterSection);
    setVisible({ ...visible, popcorn_contract: true });
    setExpanded({ ...expanded, receipt_printer: false });
  };

  const popcornCashierSectionChange = (event, newPopcornCashierSection) => {
    setPopcornCashierSection(newPopcornCashierSection);
    setVisible({ ...visible, rocket_contract: true });
    setExpanded({ ...expanded, popcorn_contract: false });
  };

  const rocketMembershipSectionChange = (event, newRocketMembershipSection) => {
    setRocketMembershipSection(newRocketMembershipSection);
    setVisible({ ...visible, implementation: true });
    setExpanded({ ...expanded, rocket_contract: false });
  };

  const implementationSectionChange = (event, newImplementationSection) => {
    setImplementationSection(newImplementationSection);
    setVisible({ ...visible, installment: true });
    setExpanded({ ...expanded, implementation: false });
  };

  const installmentSectionChange = (event, newInstallmentSection) => {
    setInstallmentSection(newInstallmentSection);
    setExpanded({ ...expanded, installment: false });
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products`, {
        params: {
          page: 1,
          perPage: 100,
          filter: JSON.stringify({ is_active: 1, country_id: 158 }),
          sort: JSON.stringify({ field: "order_no", order: "asc" }),
        },
      });

      setData(data.data.products);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const generateHardwareSectionContent = useCallback(() => {
    sethardwareSectionContent(
      <ToggleButtonGroup size="small" color="primary" value={hardwareSection} exclusive onChange={hardwareSectionChange} aria-label="Hardware Section" sx={{ display: "flex", flexWrap: "wrap" }}>
        {data.map((element) => (
          <ToggleButton key={element.product_id} value={element.product_id} sx={{ minWidth: "218px", maxWidth: "218px", marginBottom: "0.5rem", marginRight: 1.2, borderColor: "#dfdfdf !important" }}>
            <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{element.product_description}</div>} arrow>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {element.product_image !== "" && <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}${element.product_image}`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />}
                <span style={{ marginTop: "auto", textAlign: "center" }}>{element.product_name}</span>
              </div>
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }, [hardwareSection, data]);

  useEffect(() => {
    generateHardwareSectionContent();
  }, [generateHardwareSectionContent]);

  const calculatePrice = useCallback(() => {
    const selectedProduct = data.find((product) => product.product_id === hardwareSection);
    setSelectedData(selectedProduct);

    const hardwarePrice = selectedProduct?.product_price || 0;
    const cashDrawerPrice = cashDrawerSection === "1" ? selectedProduct?.cash_drawer_price * quantity.cash_drawer || 0 : 0;
    const receiptPrinterPrice = receiptPrinterSection === "1" ? selectedProduct?.receipt_printer_price * quantity.receipt_printer || 0 : 0;
    const popcornContractPrice =
      popcornCashierSection === "3" || popcornCashierSection === "6" || popcornCashierSection === "12"
        ? (popcornCashierSection === "3" || popcornCashierSection === "6" ? (popcornCashierSection === "6" ? selectedProduct?.popcorn_contract_6m_price : selectedProduct?.popcorn_contract_3m_price) : selectedProduct?.popcorn_contract_12m_price) || 0
        : 0;
    const rocketContractPrice = rocketMembershipSection === "6" || rocketMembershipSection === "12" ? (rocketMembershipSection === "6" ? selectedProduct?.rocket_membership_6m_price : selectedProduct?.rocket_membership_12m_price) : 0;
    const implementationPrice = implementationSection === "1" || implementationSection === "2" ? (implementationSection === "1" ? selectedProduct?.implementation_online_price : selectedProduct?.implementation_onsite_price) : 0;
    const adminFeePrice = installmentSection === "3" || installmentSection === "6" ? _.ceil((hardwarePrice + cashDrawerPrice + receiptPrinterPrice + popcornContractPrice + rocketContractPrice + implementationPrice) * (selectedProduct?.admin_fee_price / 100 || 0), -1) : 0;
    const totalPrice = hardwarePrice + cashDrawerPrice + receiptPrinterPrice + popcornContractPrice + rocketContractPrice + implementationPrice + adminFeePrice;
    const installmentPrice = installmentSection === "3" || installmentSection === "6" ? _.round(totalPrice / installmentSection, 2) : 0;

    setPrice({
      hardware_price: hardwarePrice,
      cash_drawer_price: cashDrawerPrice,
      receipt_printer_price: receiptPrinterPrice,
      popcorn_contract_price: popcornContractPrice,
      rocket_contract_price: rocketContractPrice,
      implementation_price: implementationPrice,
      admin_fee_price: adminFeePrice,
      total_price: totalPrice,
      installment_price: installmentPrice,
    });
  }, [data, hardwareSection, cashDrawerSection, receiptPrinterSection, popcornCashierSection, rocketMembershipSection, implementationSection, installmentSection, quantity]);

  useEffect(() => {
    calculatePrice();
  }, [calculatePrice]);

  const HardwareSection = ({ hardwareSectionContent }) => {
    return (
      <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
        <CardContent sx={{ px: "45px !important", pb: "0px !important" }}>
          <Typography variant="body2" color="text.secondary">
            {hardwareSectionContent}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh", background: "#ffffff" }}>
      <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
        <Box sx={{ marginTop: 0.5, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={"rockbell.png"} alt="icon" style={{ marginTop: 0, marginBottom: 16, width: "20%" }} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Accordion expanded={expanded.hardware} onChange={handleHardwareAccordionClick}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="hardware-content" id="hardware-header">
                <Typography variant="h6">1. Hardware</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <HardwareSection hardwareSectionContent={hardwareSectionContent} />
                <Grid item xs={12} md={12}>
                  <Button sx={{ ml: "40px", mt: 0, mb: 1 }} onClick={handleHardwareInformationOpen}>
                    Compare Hardware Information
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {visible.cash_drawer && (
              <Accordion expanded={expanded.cash_drawer} onChange={handleCashDrawerAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="cash-drawer-content" id="cash-drawer-header">
                  <Typography variant="h6">2. Cash Drawer</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ pb: 1 }}>
                          <Button onClick={reductCashDrawerQuantity}>-</Button>
                          <Button disabled sx={{ width: "140px", color: "#000000 !important" }}>
                            {quantity.cash_drawer}
                          </Button>
                          <Button onClick={addCashDrawerQuantity}>+</Button>
                        </ButtonGroup>
                        <ToggleButtonGroup size="small" color="primary" value={cashDrawerSection} exclusive onChange={cashDrawerSectionChange} aria-label="Cash Drawer Section" sx={{ display: "flex", flexWrap: "wrap" }}>
                          <ToggleButton value="1" sx={{ minWidth: "218px", maxWidth: "218px", marginBottom: "0.5rem", marginRight: 1.2, borderColor: "#dfdfdf !important" }}>
                            <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{selectedData?.cash_drawer_description || ""}</div>} arrow>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {selectedData && selectedData.cash_drawer_image !== "" && <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}${selectedData.cash_drawer_image}`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />}
                                <span style={{ marginTop: "auto", textAlign: "center" }}>Cash Drawer</span>
                              </div>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ minWidth: "218px", maxWidth: "218px", marginBottom: "0.5rem", marginRight: 1.2, borderColor: "#dfdfdf !important" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}images/none.jpg`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />
                              <span style={{ marginTop: "auto", textAlign: "center" }}>None</span>
                            </div>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}

            {visible.receipt_printer && (
              <Accordion expanded={expanded.receipt_printer} onChange={handleReceiptPrinterAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="receipt-printer-content" id="receipt-printer-header">
                  <Typography variant="h6">3. Receipt Printer</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ pb: 1 }}>
                          <Button onClick={reductReceiptPrinterQuantity}>-</Button>
                          <Button disabled sx={{ width: "140px", color: "#000000 !important" }}>
                            {quantity.receipt_printer}
                          </Button>
                          <Button onClick={addReceiptPrinterQuantity}>+</Button>
                        </ButtonGroup>
                        <ToggleButtonGroup size="small" color="primary" value={receiptPrinterSection} exclusive onChange={receiptPrinterSectionChange} aria-label="Receipt Printer Section" sx={{ display: "flex", flexWrap: "wrap" }}>
                          <ToggleButton value="1" sx={{ minWidth: "218px", maxWidth: "218px", marginBottom: "0.5rem", marginRight: 1.2, borderColor: "#dfdfdf !important" }}>
                            <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{selectedData?.receipt_printer_description || ""}</div>} arrow>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                {selectedData && selectedData.receipt_printer_image !== "" && <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}${selectedData.receipt_printer_image}`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />}
                                <span style={{ marginTop: "auto", textAlign: "center" }}>LAN RECEIPT PRINTER</span>
                              </div>
                            </Tooltip>
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ minWidth: "218px", maxWidth: "218px", marginBottom: "0.5rem", marginRight: 1.2, borderColor: "#dfdfdf !important" }}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}images/none.jpg`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />
                              <span style={{ marginTop: "auto", textAlign: "center" }}>None</span>
                            </div>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}

            {visible.popcorn_contract && (
              <Accordion expanded={expanded.popcorn_contract} onChange={handlePopcornContractAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="popcorn-contract-content" id="popcorn-contract-header">
                  <Typography variant="h6">4. Popcorn Cashier Subscription</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ToggleButtonGroup size="small" color="primary" value={popcornCashierSection} exclusive onChange={popcornCashierSectionChange} aria-label="Popcorn Cashier Subscription Section">
                        <ToggleButton value="12" sx={{ mr: 1.2, borderColor: "#dfdfdf !important" }}>
                            12 Months
                          </ToggleButton>
                          <ToggleButton value="6" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            6 Months
                          </ToggleButton>
                          <ToggleButton value="3" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            3 Months
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ ml: 1.2, borderColor: "#dfdfdf !important" }}>
                            None
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}

            {visible.rocket_contract && (
              <Accordion expanded={expanded.rocket_contract} onChange={handleRocketContractAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rocket-contract-content" id="rocket-contract-header">
                  <Typography variant="h6">5. Rocket Membership Subscription</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ToggleButtonGroup size="small" color="primary" value={rocketMembershipSection} exclusive onChange={rocketMembershipSectionChange} aria-label="Popcorn Rocket Membership Section">
                          <ToggleButton value="12" sx={{ mr: 1.2, borderColor: "#dfdfdf !important" }}>
                            12 Months
                          </ToggleButton>
                          <ToggleButton value="6" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            6 Months
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ ml: 1.2, borderColor: "#dfdfdf !important" }}>
                            None
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}

            {visible.implementation && (
              <Accordion expanded={expanded.implementation} onChange={handleImplementationAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="implementation-content" id="implementation-header">
                  <Typography variant="h6">6. Implementation</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ToggleButtonGroup size="small" color="primary" value={implementationSection} exclusive onChange={implementationSectionChange} aria-label="Implementation Section">
                        <ToggleButton value="2" sx={{ mr: 1.2, borderColor: "#dfdfdf !important" }}>
                            Onsite Training
                          </ToggleButton>
                          <ToggleButton value="1" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            Online Training
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            None
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}

            {visible.installment && (
              <Accordion expanded={expanded.installment} onChange={handleInstallmentAccordionClick}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="installment-content" id="installment-header">
                  <Typography variant="h6">7. Installment</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Card variant="outlined" sx={{ background: "#f9fafb7a", border: "unset" }}>
                    <CardContent sx={{ px: "45px !important", pb: "14px !important" }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0 }}>
                        <ToggleButtonGroup size="small" color="primary" value={installmentSection} exclusive onChange={installmentSectionChange} aria-label="Installment Section">
                          <ToggleButton value="6" sx={{ mr: 1.2, borderColor: "#dfdfdf !important" }}>
                            6 Months Installment
                          </ToggleButton>
                          <ToggleButton value="3" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            3 Months Installment
                          </ToggleButton>
                          <ToggleButton value="0" sx={{ mx: 1.2, borderColor: "#dfdfdf !important" }}>
                            None
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ background: "#f9fafb7a" }}>
              <CardHeader title="Summary" sx={{ px: 2, py: 1.5, color: "#3c3c3c" }} />
              <CardContent sx={{ pt: 0, pb: "10px !important", background: "#f9fafb7a" }}>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                      Hardware
                    </Grid>
                    {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                      RM {price.hardware_price.toFixed(2)}
                    </Grid> */}
                    {hardwareSection && (
                      <>
                        <Grid item xs={12} md={12} sx={{ pt: "6px !important", ml: 1.5 }}>
                          <div style={{ whiteSpace: "pre-line" }}>{selectedData?.product_name || ""}</div>
                        </Grid>
                      </>
                    )}
                    {cashDrawerSection === "1" && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Cash Drawer
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.cash_drawer_price.toFixed(2)}
                        </Grid> */}
                        <Grid item xs={12} md={12} sx={{ pt: "6px !important", ml: 1.5 }}>
                          <div style={{ whiteSpace: "pre-line" }}>{selectedData?.cash_drawer_description || ""} x {quantity.cash_drawer}</div>
                        </Grid>
                      </>
                    )}
                    {receiptPrinterSection === "1" && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Receipt Printer
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.receipt_printer_price.toFixed(2)}
                        </Grid> */}
                        <Grid item xs={12} md={12} sx={{ pt: "6px !important", ml: 1.5 }}>
                          <div style={{ whiteSpace: "pre-line" }}>{selectedData?.receipt_printer_description || ""} x {quantity.receipt_printer}</div>
                        </Grid>
                      </>
                    )}
                    {(popcornCashierSection === "3" || popcornCashierSection === "6" || popcornCashierSection === "12") && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Popcorn Cashier ({popcornCashierSection} Months)
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.popcorn_contract_price.toFixed(2)}
                        </Grid> */}
                      </>
                    )}
                    {(rocketMembershipSection === "6" || rocketMembershipSection === "12") && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Rocket Membership ({rocketMembershipSection} Months)
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.rocket_contract_price.toFixed(2)}
                        </Grid> */}
                      </>
                    )}
                    {(implementationSection === "1" || implementationSection === "2") && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Implementation ({implementationSection === 1 ? "Online Training" : "Onsite Training"})
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.implementation_price.toFixed(2)}
                        </Grid> */}
                      </>
                    )}
                    {(installmentSection === "3" || installmentSection === "6") && (
                      <>
                        <Grid item xs={12} md={12} sx={{ color: "#303030" }}>
                          + Installment ({installmentSection} Months)
                        </Grid>
                        {/* <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
                          RM {price.admin_fee_price.toFixed(2)}
                        </Grid> */}
                      </>
                    )}
                    {hardwareSection && cashDrawerSection && receiptPrinterSection && popcornCashierSection && rocketMembershipSection && implementationSection && installmentSection && (
                      <>
                        <Grid item xs={6} md={6} sx={{ mt: 1 }}>
                          <Typography gutterBottom variant="h5" component="div">
                            Total
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} sx={{ mt: 1 }} style={{ textAlign: "right" }}>
                          <Typography gutterBottom variant="h5" component="div">
                            RM {price.total_price.toFixed(2)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {!(hardwareSection && cashDrawerSection && receiptPrinterSection && popcornCashierSection && rocketMembershipSection && implementationSection && installmentSection) && (
                      <>
                        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ color: "#ff7600" }}>
                            Select all options to view price
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {hardwareSection && cashDrawerSection && receiptPrinterSection && popcornCashierSection && rocketMembershipSection && implementationSection && installmentSection && (installmentSection === "3" || installmentSection === "6") && (
                      <>
                        <Grid item xs={6} md={6}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ color: "#ff7600" }}>
                            {installmentSection} Months Installment
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ color: "#ff7600" }}>
                            RM {price.installment_price.toFixed(2)} / Month
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <PriceHardwareDetail open={open.hardware} handleClose={() => handleClose("hardware")} data={data} />
      </Container>
    </div>
  );
};
