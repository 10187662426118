import React, { useEffect } from "react";
import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const PublicLayout = ({ excludedPaths = [] }) => {
  const { token } = useAuth();
  const outlet = useOutlet();

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "token" && event.newValue !== "null") {
        const pathname = window.location.pathname;
        if (pathname === "/login") {
          //
        }
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  });

  if (token && !excludedPaths.includes(window.location.pathname)) {
    return <Navigate to="/dashboard" replace />;
  }

  return <div>{outlet}</div>;
};
