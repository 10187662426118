import React, { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Autocomplete, Checkbox } from "@mui/material";
import { Icons } from "../../../utils/Icon";

import axios from "axios";
import Swal from "sweetalert2";

export default function CreateGroupForm({ open, handleClose, fetchData }) {
  const [newData, setNewData] = useState({});

  const [errors, setErrors] = useState({ group_name: false, group_description: false });
  const [helperText, setHelperText] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const [users, setUsers] = useState([]);
  const [selectedUserValues, setSelectedUserValues] = useState([]);

  const setError = async (field, message) => {
    await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
  };

  const validate = async (field, value) => {
    if (field === "group_name" && value === "") {
      await setError("group_name", "Name is required.");
    } else if (field === "group_description" && value === "") {
      await setError("group_description", "Description is required.");
    } else {
      await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
      await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
    }
  };

  useEffect(() => {
    if (open) {
      setNewData({ group_name: "", group_description: "" });
      setErrors({ group_name: false, group_description: false });
      setHelperText({});
      setErrorMessage("");
      setSelectedUserValues([]);

      axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select").then((response) => {
        setUsers(response.data.data.users);
      });
    }
  }, [open]);

  const handleCreateGroup = async () => {
    let isValid = true;

    if (newData["group_name"] === "") isValid = false;
    if (newData["group_description"] === "") isValid = false;

    Object.keys(newData).forEach(async (field) => {
      await validate(field, newData[field]);
    });

    if (isValid) {
      await setErrorMessage("");
      await createGroup();
    } else {
      await setErrorMessage(`Please fill all the required fields.`);
    }
  };

  const createGroup = async () => {
    try {
      const { group_name, group_description } = newData;
      const selected_users = selectedUserValues.map((user) => user.user_id);
      const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/groups/create", {
        group_name,
        group_description,
        selected_users,
      });

      if (response.status === 201) {
        handleClose();
        Swal.fire({
          title: "Success",
          text: "Group created successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while creating the group. Please try again later.");
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Group</DialogTitle>
      {errorMessage && (
        <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent style={{ paddingBottom: "0px" }}>
        <TextField
          autoFocus
          autoComplete="off"
          margin="dense"
          id="group_name"
          label="Name"
          type="text"
          fullWidth
          required
          error={errors.group_name}
          helperText={helperText.group_name}
          onChange={(e) => {
            setNewData({ ...newData, group_name: e.target.value });
            validate("group_name", e.target.value);
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="group_description"
          label="Description"
          type="group_description"
          multiline
          rows={3}
          fullWidth
          required
          error={errors.group_description}
          helperText={helperText.group_description}
          onChange={(e) => {
            setNewData({ ...newData, group_description: e.target.value });
            validate("group_description", e.target.value);
          }}
        />
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={users}
          getOptionLabel={(users) => users.name}
          defaultValue={[]}
          value={selectedUserValues}
          onChange={(e, value) => setSelectedUserValues(value)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" label="Users" />}
        />
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleCreateGroup} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
          Create
        </Button>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
