import React, { useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, List, ListItem, ListItemText, Divider, Paper } from "@mui/material";

export default function PriceHardwareDetail({ open, handleClose, data }) {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Hardware Information</DialogTitle>
      <DialogContent style={{ paddingBottom: "0px" }}>
        <Grid container spacing={2}>
          {data.map((element) => (
            <Grid key={element.product_id} item xs={12} md={4}>
              <List sx={{ width: "100%", bgcolor: "background.paper", borderRadius: "4px", border: "1px solid rgba(0, 0, 0, 0.12)" }}>
                <ListItem sx={{ justifyContent: "center", minHeight: "300px", maxHeight: "300px" }}>
                  <Paper elevation={0} sx={{ minWidth: "260px", maxWidth: "260px", marginBottom: "0.5rem", marginRight: "0.5rem", borderColor: "#dfdfdf !important" }}>
                    <div style={{ justifyContent: "center", alignItems: "center" }}>{element.product_image !== "" && <img src={`${process.env.REACT_APP_WEBSITE_BACKEND_URL}${element.product_image}`} alt="Unknown" style={{ margin: "4px", width: "100%", height: "auto" }} />}</div>
                  </Paper>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={element.product_name} sx={{ textAlign: "center" }} />
                </ListItem>
                <Divider />
                <ListItem alignItems="flex-start" sx={{ minHeight: "240px", maxHeight: "500px" }}>
                  <div style={{ whiteSpace: "pre-line", overflow: "auto", fontSize: 14 }}>{element.product_description}</div>
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
