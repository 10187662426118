import { useAuth } from "./hooks/useAuth";
import { GetTokenAuth, SetAxiosAuthToken } from "./utils/Token";
import AppRoutes from "./Routes";
import "./App.css";

export default function App() {
  const { token } = useAuth();

  if (token) {
    SetAxiosAuthToken(GetTokenAuth(token));
  }

  return <AppRoutes />;
}
