import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, InputAdornment, IconButton, Alert } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import axios from "axios";
import Swal from "sweetalert2";

export default function ChangePasswordForm({ open, handleClose, rowData }) {
  const [newData, setNewData] = useState({});

  const [errors, setErrors] = useState({ new_password: false, confirm_password: false });
  const [showPassword, setShowPassword] = useState(false);
  const [helperText, setHelperText] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const setError = async (field, message) => {
    await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
    await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
  };

  const validate = async (field, value) => {
    if (field === "new_password" && value.length < 6) {
      await setError("new_password", "Password must be at least 6 characters.");
    } else if (field === "confirm_password" && value.length < 6) {
      await setError("confirm_password", "Password must be at least 6 characters.");
    } else {
      await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
      await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
    }
  };

  useEffect(() => {
    if (open) {
      setNewData({ new_password: "", confirm_password: "" });
      setErrors({ new_password: false, confirm_password: false });
      setShowPassword(false);
      setHelperText({});
      setErrorMessage("");
    }
  }, [open]);

  const handleChangePassword = async () => {
    let isValid = true;

    if (newData["new_password"].length < 6) isValid = false;
    if (newData["confirm_password"].length < 6) isValid = false;

    Object.keys(newData).forEach(async (field) => {
      await validate(field, newData[field]);
    });

    if (isValid) {
      if (newData["new_password"].length >= 6 && newData["confirm_password"].length >= 6) {
        if (newData["new_password"] !== newData["confirm_password"]) {
          await setErrorMessage("The new password and the confirmation do not match.");
          return;
        }
      }
      await setErrorMessage("");
      await changePassword();
    } else {
      await setErrorMessage("Please fill all the required fields.");
    }
  };

  const changePassword = async () => {
    try {
      const { new_password, confirm_password } = newData;
      const response = await axios.put(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/${rowData.user_id}/change-password`, {
        new_password,
        confirm_password,
      });

      if (response.status === 200) {
        handleClose();
        Swal.fire({
          title: "Success",
          text: "Password changed successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while changing the password. Please try again later.");
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Change User Password</DialogTitle>
      {errorMessage && (
        <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent style={{ paddingBottom: "0px" }}>
        <TextField
          autoComplete="off"
          margin="dense"
          id="new_password"
          label="New Password (At least 6 characters)"
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          error={errors.new_password}
          helperText={helperText.new_password}
          onChange={(e) => {
            setNewData({ ...newData, new_password: e.target.value });
            validate("new_password", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="confirm_password"
          label="Confirm New Password (At least 6 characters)"
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          error={errors.confirm_password}
          helperText={helperText.confirm_password}
          onChange={(e) => {
            setNewData({ ...newData, confirm_password: e.target.value });
            validate("confirm_password", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions style={{ margin: "12px" }}>
        <Button onClick={handleChangePassword} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
          Change
        </Button>
        <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
