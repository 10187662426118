import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Alert, Stack, Button, TextField, Box, Typography, Container, Backdrop, InputAdornment, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Copyright } from "../layouts/Copyright";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import HashLoader from "react-spinners/HashLoader";
import Swal from "sweetalert2";

export const ResetPasswordPage = () => {
  const classes = CustomStyles;

  const { token } = useParams();
  const location = useLocation();
  const message = location.state !== null && location.state.message !== null ? location.state.message : null;

  const [showPassword, setShowPassword] = useState(false);

  const [backdrop, setBackdrop] = useState(false);

  const showBackdrop = (visible) => {
    setBackdrop(visible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const resetPasswordPayload = {
      newPassword: data.get("new-password"),
      confirmPassword: data.get("confirm-password"),
    };

    if (resetPasswordPayload.newPassword.length < 6 || resetPasswordPayload.confirmPassword.length < 6) {
      return Swal.fire({
        icon: "error",
        title: "Invalid password",
        text: "Your password must be at least 6 characters long.",
        confirmButtonColor: "#fe7320",
      });
    } else if (resetPasswordPayload.newPassword !== resetPasswordPayload.confirmPassword) {
      return Swal.fire({
        icon: "error",
        title: "Invalid password",
        text: "Passwords do not match. Please ensure the new password and confirmation password are the same.",
        confirmButtonColor: "#fe7320",
      });
    }

    showBackdrop(true);

    setTimeout(() => {
      axios
        .post(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/reset-password/${token}`, resetPasswordPayload)
        .then((response) => {
          if (response.data === undefined || response.data.data === undefined) alert(response.data.message);
          Swal.fire({
            title: "Success",
            text: "Your password has been reset successfully.",
            icon: "success",
            confirmButtonColor: "#fe7320",
            allowOutsideClick: false,
          }).then(() => {
            window.location.replace("https://services.rockets.com.my/login");
          });
          showBackdrop(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Invalid Token or Token Expired",
            text: "Oops, something went wrong. please try again later.",
          });
          console.log(err);
          showBackdrop(false);
        });
    }, 1000); // Delay for Handle Local Storage Change
  };

  useEffect(() => {
    setShowPassword(false);
  }, []);

  return (
    <div className={classes.parent}>
      <div className={classes.background} />
      <Container component="main" maxWidth="xs" sx={{ position: "relative", top: 120, border: "1px solid #dfdfdf", borderRadius: "8px", bgcolor: "#ffffffba" }}>
        <Box sx={{ marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {message !== null && (
            <Stack sx={{ marginBottom: 2, width: "100%" }} spacing={2}>
              <Alert style={{ whiteSpace: "pre-wrap" }} severity="error">
                {message}
              </Alert>
            </Stack>
          )}
          <Typography component="h1" variant="h5" fontWeight="bold" sx={{ marginTop: 1, marginBottom: 2, color: "#fe7320" }}>
            Reset Your Password
          </Typography>
          <Typography sx={{ marginTop: 1, marginBottom: 2, fontSize: 13, textAlign: "center" }}>Enter your new password below.</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              autoComplete="off"
              autoFocus
              margin="normal"
              fullWidth
              id="new_password"
              label="New Password (At least 6 characters)"
              name="new-password"
              type={showPassword ? "text" : "password"}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              autoComplete="off"
              margin="normal"
              fullWidth
              id="confirm_password"
              label="Confirm Password (At least 6 characters)"
              name="confirm-password"
              type={showPassword ? "text" : "password"}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, fontWeight: "bold", color: "white" }}>
              Reset Password
            </Button>
          </Box>
        </Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
          <HashLoader color="#ffffff" loading={true} size={150} />
        </Backdrop>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </div>
  );
};
